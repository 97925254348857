<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Advertising from '@/services/Advertising';
  import ModalViewAdvertisement from "@/components/modals/advertising/modalViewAdvertisement";
  //import ModalAddAdvertisement from "@/components/modals/advertising/modalAddAdvertisement";
  import ModalAddAdvertisementNew from "@/components/modals/advertising/modalAddAdvertisementNew";
 //import ModalEditAdvertisement from "@/components/modals/advertising/modalEditAdvertisement";
  import ModalEditAdvertisementNew from "@/components/modals/advertising/modalEditAdvertisementNew";
  import Swal from "sweetalert2";
  import Teams from '@/services/Teams'


  export default {
  components: { Layout, PageHeader, ModalViewAdvertisement, ModalAddAdvertisementNew,  ModalEditAdvertisementNew },
  page: {
      title: "Advertising",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        accessView: JSON.parse(process.env.VUE_APP_SIDE_MENU),
        error: null,
        filterData: false,
        tableData: [],
        FormatedtableData: [],
        title: "Advertisements",
        items: [
          {
            text: "Advertising",
          },
          {
            text: "Advertisements",
            active: true,
          },
        ],
        filterInput: {
          status: 'all',
        },
        advertisementStatus: [
          { text: 'All', value: 'all' },
          { text: 'Yes', value: '1' },
          { text: 'No', value: '0' },
        ],
        isBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "placement_timestamp",
        sortDesc: true,
        advertisementData:[],
        placementTypeData:[],
        player : {} ,
        allPlayers :[] ,
        match : {} ,
        allMatches :[] ,
        fields: [
            {
              key: "placement_name",
              label: "Advertisement Type",
              sortable: true,
            },
            {
              key: "attributes",
              label: "Advertisement Attributes"
            },
            {
              key: "placement_active",
              label: "Active"
            },
            {
                key: "sponsor_name",
                label: "Sponsor",
                sortable: true,
            },
            {
              key: "placement_timestamp",
              label: "Created",
              sortable: true,
            },
            "action"
        ],
        placementTypes:[] ,

    };
  },
  async created() {
    this.getAdvertisements();
    this.loadSquadData();
    this.loadFixtures();
    this.getPlacementTypes();
  },
  methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      async getAdvertisements(){
          try {
            this.filterData = true;
            this.toggleBusy();
            var filters = this.getFilters()
            const response = await Advertising.getAdvertisements(filters)
            this.tableData = response.data.data
            this.totalRows = this.tableData.length
          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
            this.totalRows = 0
          } finally {
            this.toggleBusy();
          }
        },

        getFilters(){
          var filter_string = '';

          if(this.filterInput.status) {
            filter_string += (this.filterInput.status) ? '&type=' + this.filterInput.status : "";
          }

          return filter_string = filter_string.substring(1);
        },

        async getPlacementTypes(){

          await Advertising.getPlacementTypes()
              .then(response => {
                this.placementTypes = response.data.data
                if(this.placementTypes.length > 0){
                  this.placement_type_id = this.placementTypes[0].id
                }
              })
              .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
              }).finally(() => {

              })
        },

      async loadSquadData() {
          try {
              const allPlayers = [];
              this.toggleBusy();
              const response = await Teams.getTeamSquad('all');
              const squadData = response.data.squad;

              this.squadData = response.data;

              squadData.forEach(team => {
                  if (team.players && Array.isArray(team.players)) {
                      allPlayers.push(...team.players);
                  }
              });

              this.player = allPlayers;

              this.toggleBusy();
          } catch (error) {
              this.toggleBusy();
              this.error = error.response.data.error ? error.response.data.error : '';
          }
      },

      async loadFixtures() {
            try {
              //  const allMatches = [];
                this.toggleBusy();
                const response = await Teams.getFixtures();
              //  const fixtures = response.data.fixtures;
                // Filter out  with "past" equal to "PAST"
                var fixtures = response.data.fixtures.filter(obj => obj.past !== "PAST");
                this.match = fixtures;
                this.toggleBusy();
            } catch (error) {
                this.toggleBusy();
                this.error = error.response.data.error ? error.response.data.error : '';
            }
        },


      getPlayerNameByPlayerId(playerId) {


          if (Array.isArray(this.player)) {
              const player = this.player.find((p) => p.id === playerId);
              if (player) {
                  return `${player.first_name} ${player.last_name}`;
              }
          }
          return '';
      },
        callmodalviewAdvertisement(data) {
          this.$bvModal.show("view_advertisement");
          this.advertisementData = data
        },

      /*  callModalAddAdvertisement(){
          this.$bvModal.show("add_advertisement");
        },
      */

        callModalAddAdvertisementNew(placementType){
          this.$bvModal.show("add_advertisement_new");
          this.placementTypeData=placementType;
        },

      /*  callmodaleditAdvertisement(data) {
          this.$bvModal.show("edit_advertisement");
          this.advertisementData = data
        },
      */

       callmodaleditAdvertisementNew(data) {

          var temp = data
          delete temp.attributes.Player;
          delete temp.attributes.Match;
          this.$bvModal.show("edit_advertisement_new");
          this.advertisementData = temp
       },

        removeAdvertisement(placement_id){
          Swal.fire({
            title: "Are you sure?",
            text: "Remove From Advertisements !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes!",
          }).then((result) => {
            if (result.value) {
              Advertising.deleteSponsorAdvertisement(placement_id)
                  .then((response) => {
                    const res   = response.data.data  ? response.data.data  : false;
                    const error = response.data.error ? response.data.error : '';
                    if(res && error==''){
                      this.getAdvertisements()
                      this.successmsg("Advertisement Successfully Removed")
                    }else{
                      this.failedmsg("Advertisement Removal Failed")
                    }
                  })
                  .catch(error => {
                    this.failedmsg('Fail!',error)
                  });
            }
          });
        },

      },

      computed: {

          formattedTableData() {
              return this.tableData.map((item) => {
                  const formattedItem = { ...item };
                  if (item.attributes.player_id && this.player.length > 0) {
                      const player = this.player.find((p) => p.id === parseInt(item.attributes.player_id));
                      if (player) {
                          formattedItem.attributes.Player = `${player.first_name} ${player.last_name}`;
                      }
                  }
                  if ( typeof item.attributes.match_id !== 'undefined' && this.match.length > 0) {
                      const match = this.match.find((m) => m.match_id === (item.attributes.match_id));
                      if (match) {
                          formattedItem.attributes.Match = `${match.home_team_short} : ${match.away_team_short} - ${match.date_formatted}`;
                      }
                  }
                  return formattedItem;

              });
          },

      },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
              <template>
                <b-dropdown variant="success" left  >
                  <template #button-content>
                    Create New<i class="mdi mdi-chevron-down me-2"></i>
                  </template>
                   <b-dropdown-item v-for="(item, index) in placementTypes" :key="index" @click="callModalAddAdvertisementNew(item)">
                        {{ item.name }} ({{ item.short }})
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            <div class="row mt-4">
             <!-- <div class="col-md-12">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                     @click="callModalAddAdvertisement">
                    <i class="mdi mdi-plus me-2"></i>
                    Create New
                  </a>
                </div>
              </div> -->
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                          class="text-body fw-semibold pb-2 d-block"
                          data-toggle="collapse"
                          href="javascript: void(0);"
                          role="button"
                          aria-expanded="false"
                          v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                      <div class="row">
                        <div class="col-sm-12 col-md-3">
                          <label class="control-label form-label">Active</label>
                          <b-form-select class="form-control" aria-describedby="status-feedback" :options="advertisementStatus" v-model="filterInput.status"></b-form-select>
                        </div>
                      
                      </div>

                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getAdvertisements">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>

                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="card" v-show="filterData">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center" >
                <h4 class="card-title m-0">Total Advertisements: {{totalRows}}</h4>
              </div>
            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-show="filterData">
              <b-table
                      :items="formattedTableData"
                      :busy="isBusy"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      empty-text="No Data Found"
              >

                <template v-slot:cell(placement_name)="data">
                  <div >{{ data.item.placement_name }} ({{  data.item.placement_type_short }})</div>
                </template>

                <template v-slot:cell(attributes)="data">
                  <div v-for="(value, key) in data.item.attributes" :key="key">
                     {{ key }}: {{ value }}
                  </div>
                  <!-- Display the player name based on function code -->
                  <!--<div v-if="data.item.attributes.player_id && data.item.attributes.player_name !== ''">-->
                    <!--Player Name: {{ data.item.attributes.player_name }}-->
                  <!--</div>-->
                </template>

                <template v-slot:cell(placement_active)="data">
                  <div v-html="format_status(data.item.placement_active ? 'yes' : 'no')"></div>
                </template>
                <template v-slot:cell(sponsor_name)="data">
                  <router-link :to="{ path: `/advertising/sponsor/${data.item.media[0].sponsor_id}`}">
                  <div >{{ data.item.media[0].name }} </div>
                  </router-link>
                </template>


                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                          href="javascript:void(0);"
                          @click="callmodalviewAdvertisement(data.item)"
                          class="px-2 text-primary"
                          title="View Advertisement"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                     <!-- <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Advertisement" @click="callmodaleditAdvertisement(data.item)">
                        <i class="uil uil-pen font-size-18"></i>
                      </a>-->
                       <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Advertisement" @click="callmodaleditAdvertisementNew(data.item)">
                          <i class="uil uil-pen font-size-18"></i>
                       </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Advertisement" @click="removeAdvertisement(data.item.placement_id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MODALS -->
    <ModalViewAdvertisement :advertisementData="advertisementData" ></ModalViewAdvertisement>
    <!--<ModalAddAdvertisement @refreshData="getAdvertisements"></ModalAddAdvertisement>-->
    <ModalAddAdvertisementNew :placementTypeData="placementTypeData" @refreshData="getAdvertisements"></ModalAddAdvertisementNew>
    <!--<ModalEditAdvertisement :advertisementData="advertisementData" @refreshData="getAdvertisements"></ModalEditAdvertisement>-->
    <ModalEditAdvertisementNew :advertisementData="advertisementData" :placementTypeData="placementTypeData" @refreshData="getAdvertisements"></ModalEditAdvertisementNew>
    <!-- /MODALS -->

  </Layout>
</template>
